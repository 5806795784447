<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="점검기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_CHECK_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="점검유형"
            name="sopFireFightingCheckTypeCd"
            v-model="searchParam.sopFireFightingCheckTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="SOP_FIRE_FIGHTING_CHECK_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            label="진행단계"
            name="sopFireFightingCheckStepCd"
            v-model="searchParam.sopFireFightingCheckStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <q-table
      grid
      title=""
      :data="grid.data"
      :columns="grid.columns"
      hide-header
      hide-bottom
      :rows-per-page-options="[0]"
      virtual-scroll
    >
      <template v-slot:top-right>
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
        </q-btn-group>
      </template>
      <template v-slot:item="props">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
            <q-card-section horizontal class="bg-grey-2">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.checkName }}</div>
              </q-card-section>
            </q-card-section>
            <q-card-section class="grid-card-etc">
              <div class="text-grid-etc">
                {{ props.row.checkPeriod }}
              </div>
              <div class="text-grid-etc">
                {{ props.row.sopFireFightingCheckTypeName }}
              </div>
              <div :class="['text-grid-etc']">
                {{ props.row.sopFireFightingCheckStepName }}
              </div>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
      <q-card class="mobileTableCardLayer">
        <q-card-section horizontal class="bg-grey-2 text-center">
          <q-card-section class="q-pt-xs">
            <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingCheck',
  data() {
    return {
      searchParam: {
        plantCd: null,
        sopFireFightingCheckTypeCd: null,
        sopFireFightingCheckStepCd: null,
        period: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: "sopFireFightingCheckTypeName",
            field: "sopFireFightingCheckTypeName",
            style: 'width:100px',
            label: "점검유형",
            align: "center",
            sortable: true,
          },
          {
            name: 'sopFireFightingCheckStepName',
            field: 'sopFireFightingCheckStepName',
            label: '진행상태',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: "checkPeriod",
            field: "checkPeriod",
            label: "점검기간",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.fft.fireFightingCheck.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '소화설비 점검 상세';
      this.popupOptions.param = {
        sopFireFightingCheckId: row ? row.sopFireFightingCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./fireFightingCheckDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
